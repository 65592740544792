import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { formatNavUsers, formatProducts, formatProjectsNav, formatRepcon, formatUsers } from "./settingsActionsUtils";
import {
  ADD_NEW_USER,
  USERS_FORM_TOOGLE_LOADING,
  SHOW_NOTIFICATION,
  UPDATE_USER,
  SET_USER_LIST,
  SET_NAVUSER_LIST,
  SET_PROFILE,
  SET_PRODUCTS_LIST,
  SET_REPCON_LIST,
  SET_PROJECTSNAV_LIST,
} from "../constants";
import { keyBy } from "lodash";

/* USER LIST */
export const fetchUsers = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.users.list)
      .then((response) => {
        const users = formatUsers(response.data);
        dispatch({ type: SET_USER_LIST, payload: keyBy(users, "_id") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchNavUsers = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.users.navUsers)
      .then((response) => {
        const users = formatNavUsers(response.data);
        dispatch({ type: SET_NAVUSER_LIST, payload: keyBy(users, "value") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};


export const fetchProducts = (pf) => {
  return async (dispatch) => {
    const response = await axios
      .get(pf ? API.users.productsPF : API.users.products)
      .then((response) => {
        const users = formatProducts(response.data);
        dispatch({ type: SET_PRODUCTS_LIST, payload: keyBy(users, "value") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchRepcon = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.users.repcon)
      .then((response) => {
        const users = formatRepcon(response.data);
        dispatch({ type: SET_REPCON_LIST, payload: keyBy(users, "value") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

export const fetchProjectsNav = () => {
  return async (dispatch) => {
    const response = await axios
      .get(API.users.projectsNav)
      .then((response) => {
        const data = formatProjectsNav(response.data);
        dispatch({ type: SET_PROJECTSNAV_LIST, payload: keyBy(data, "No") });
        return response.status;
      })
      .catch((err) => err);
    return response;
  };
};

// USERS
export const createUser = (newUser) => {
  return async (dispatch) => {
    dispatch({ type: USERS_FORM_TOOGLE_LOADING });
    const response = await axios
      .post(API.users.create, newUser)
      .then((response) => {
        const { _id } = response.data;
        const user = formatUsers({ ...newUser, _id });
        dispatch({ type: ADD_NEW_USER, payload: user });
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "success",
            message: "Usuario creado con éxito.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const updateUser = (updatedUser) => {
  return async (dispatch, getState) => {
    dispatch({ type: USERS_FORM_TOOGLE_LOADING });
    const response = await axios
      .put(`${API.users.edit}${updatedUser?._id}`, updatedUser)
      .then((response) => {
        const user = formatUsers(response.data);
        dispatch({ type: UPDATE_USER, payload: user });
        if (getState().profile?._id === updatedUser._id) {
          dispatch({ type: SET_PROFILE, payload: response.data });
        }
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Usuario actualizado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};

export const deleteUsers = (Ids) => {
  return async (dispatch) => {
    dispatch({ type: USERS_FORM_TOOGLE_LOADING });
    const response = await axios
      .delete(`${API.users.delete}`, { data: Ids })
      .then((response) => {
        const users = formatUsers(response.data);
        dispatch({ type: SET_USER_LIST, payload: keyBy(users, "_id") });
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        dispatch({
          type: SHOW_NOTIFICATION,
          payload: {
            show: true,
            status: "info",
            message: "Usuario eliminado.",
          },
        });
        return response?.status;
      })
      .catch((err) => {
        dispatch({ type: USERS_FORM_TOOGLE_LOADING });
        return err;
      });
    return response;
  };
};
